import React from 'react'
import CustomNextSeo from 'components/CustomNextSeo'
import Error404 from 'components/Error404/Error404'

const Error404Page: React.FC = () => {
  return (
    <>
      <CustomNextSeo title="Page Not Found" />
      <Error404 />
    </>
  )
}

export default Error404Page
