import React from 'react'
import styles from './Error404.module.scss'
import Error404Image from './images/error404.svg'
import { Container, FlexColumn, FlexRow } from 'components/common/Containers'
import { H1 } from 'components/common/Texts/H1/H1'
import { Button } from 'components/common/Buttons/Button'
import { H2 } from 'components/common/Texts/H2/H2'
import Link from 'next/link'
import { RouteEnum } from 'enums/RouteEnum'

const Error404: React.FC = () => {
  return (
    <Container>
      <div className={styles.content}>
        <FlexRow className={styles.row} gap="5rem" align="center">
          <div className={styles.imageWrapper}>
            <Error404Image />
          </div>
          <FlexColumn align="normal" gap="2rem">
            <FlexColumn gap="1rem" align="normal">
              <H1>Oops!</H1>
              <H2>Sorry, Page Not Found!</H2>
            </FlexColumn>

            <Link href={RouteEnum.HOME} passHref legacyBehavior>
              <Button size="lg">Return to Home</Button>
            </Link>
          </FlexColumn>
        </FlexRow>
      </div>
    </Container>
  );
}

export default Error404
